/*eslint-disable*/

import Vue from 'vue';

import Router from 'vue-router';

import dotenv from 'dotenv';
import service from './services';
import jwt from 'jsonwebtoken';
import NProgress from 'nprogress';

dotenv.config();

Vue.use(Router);

const meta = {
  title: process.env.VUE_APP_SITE_TITLE,
  metaTags: [
    {
      name: 'description',
      content: process.env.VUE_APP_SITE_DESC,
    },
    {
      property: 'og:description',
      content: process.env.VUE_APP_SITE_DESC,
    },
  ],
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('./components/Login.vue'),
    meta: meta,
  },
  {
    path: '/testeCamera',
    name: 'testeCamera',
    component: () => import('./components/testeCamera.vue'),
    meta: meta,
  },
  {
    path: '/testeCamera2',
    name: 'testeCamera2',
    component: () => import('./components/testeCamera2.vue'),
    meta: meta,
  },
  {
    path: '/testeCamera3',
    name: 'testeCamera3',
    component: () => import('./components/testeCamera3.vue'),
    meta: meta,
  },
  {
    path: '/verificarDados',
    name: 'verificarDados',
    component: () => import('./components/verificarDados.vue'),
    meta: meta,
  },
  {
    path: '/confirmarDados',
    name: 'confirmarDados',
    component: () => import('./components/confirmarDados.vue'),
    meta: meta,
  },
  {
    path: '/trocarSenhaAssociado',
    name: 'trocarSenhaAssociado',
    component: () => import('./components/trocarSenhaAssociado.vue'),
    meta: meta,
  },

  {
    path: '/trocarSenha',
    name: 'trocarSenha',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/TrocarSenha.vue'),
    meta: meta,
  },
  {
    path: '/assinaTermo',
    name: 'assinaTermo',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/assinaTermo.vue'),
    meta: meta,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/DashBoard.vue'),
    meta: meta,
  },
  {
    path: '/DetalheCotacao/:idCotacao',
    name: 'DetalheCotacao',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/DetalheProposta'),
    meta: meta,
  },
  {
    path: '/NovaCotacao',
    name: 'NovaCotacao',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/NovaCotacao'),
    meta: meta,
  },
  {
    path: '/NovaPreCotacao',
    name: 'Nova Pre Cotacao',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/NovaPreCotacao.vue'),
    meta: meta,
  },
  {
    path: '/ConversaoPreCotacao/:idPreCotacao',
    name: 'ConversaoPreCotacao',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/NovaCotacao.vue'),
    meta: meta,
  },
  {
    path: '/Regulamento',
    name: 'Regulamento',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/Regulamento'),
    meta: meta,
  },
  {
    path: '/CartaoAssociado',
    name: 'CartaoAssociado',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/CartaoAssociado'),
    meta: meta,
  },
  {
    path: '/Assistencia',
    name: 'Assistencia',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/Assistencia'),
    meta: meta,
  },
  {
    path: '/Eventos',
    name: 'Eventos',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/Eventos'),
    meta: meta,
  },
  {
    path: '/editarEvento/:idEvento/:passo',
    name: 'editarEvento',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/EditarEvento'),
    meta: meta,
  },
  {
    path: '/Veiculos',
    name: 'Veiculos',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/Veiculos'),
    meta: meta,
  },
  {
    path: '/Termos',
    name: 'Termos',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/Termos'),
    meta: meta,
  },
  {
    path: '/Boletos',
    name: 'Boletos',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/Boletos'),
    meta: meta,
  },
  {
    path: '/MeusDados',
    name: 'MeusDados',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/MeusDados'),
    meta: meta,
  },
  {
    path: '/FormaPagamento',
    name: 'FormaPagamento',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/FormaPagamento'),
    meta: meta,
  },
  {
    path: '/Propostas/:status',
    name: 'Propostas',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/Cotacoes'),
    meta: meta,
  },
  {
    path: '/AtualizarProdutosCotacao/:chave',
    name: 'AtualizarProdutosCotacao',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/AtualizarProdutosCotacao'),
    meta: meta,
  },
  {
    path: '/AceiteCotacao/:chave',
    name: 'AceiteCotacao',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/AceiteCotacao_novo'),
    meta: meta,
  },
  {
    path: '/AceiteCotacao/:chave/:passo',
    name: 'AceiteCotacaoPasso',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/AceiteCotacao_novo'),
    meta: meta,
  },
  {
    path: '/AceiteCotacao/:chave/:passo/:fotoAtual',
    name: 'AceiteCotacaoPassoFoto',
    beforeEnter: function (to, from, next) {
      isAuth(to, next);
    },
    component: () => import('./components/AceiteCotacao_novo'),
    meta: meta,
  },
  {
    path: '/opt-in-whatsapp',
    name: 'Opt-In Whatsapp',
    component: () => import('./components/login/opt-in-whatsapp'),
    meta,
  },
  {
    path: '/login-empresa',
    name: 'login-empresa',
    component: () => import('./components/multiple-empresa/select-empresa'),
    meta,
  },
];

const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

function isAuth(to, next) {
  const token = localStorage.getItem('user-token');

  localStorage.setItem('rotaEntrada', window.location.href.toString());
  let idEmpresa = localStorage.getItem('idEmpresa');

  if (!token) {
    next(`/?idEmpresa=${idEmpresa}`);
    return;
  } else {
    const decoded = jwt.decode(token);
    if (!decoded) {
      next(`/?idEmpresa=${idEmpresa}`);
      return;
    } else {
      idEmpresa = decoded.id_empresa;
      //confere exp
      if (Date.now() >= decoded.exp * 1000) {
        next(`/?idEmpresa=${idEmpresa}`);
        return;
      }
    }
  }
  next();
}

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el),
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
